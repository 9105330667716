export default {
  loadingModal: 'w-[434px] !left-auto',
  titleWrapper: 'h-[32px] mt-[32px] mb-[12px] flex items-center bg-[#E6EEF3] px-[10px] rounded',
  titleText: 'font-inter text-[14px] text-[#585D69] uppercase',
  modal: 'fixed top-0 bottom-0 left-0 right-0 bg-[#10182880] z-10 flex justify-end overflow-y-scroll z-10',
  wrapper: 'min-h-screen h-fit w-[434px] bg-color-white px-[20px] py-[28px]',
  header: 'sticky top-0 py-2 flex w-full justify-between mb-4 bg-color-white z-10',
  closeModalIcon: 'bg-[#E7E8EA] flex items-center justify-center h-[40px] w-[40px] rounded-full cursor-pointer',
  button: 'flex gap-2 px-3 py-2 rounded-lg border text-[#0a52a1] border-[#0a52a1] hover:bg-[#0a52a1] hover:text-[#ffffff] duration-300',
  entriesWrapper: 'flex items-center justify-between gap-1 pr-2',
  surveyTitle: 'font-interSemibold text-[24px] text-color-text max-w-[355px] pr-2 py-1 rounded mb-[-8px]',
  fieldValue: 'text-color-text',
  imageInput: 'hidden',
  uploadButton: 'mt-[51.5px] ml-2 bg-[#9DBAD9] h-[40px] w-[40px] min-h-[40px] min-w-[40px] rounded-full flex justify-center items-center',
  categoryContent: 'flex items-center gap-3.5',
  categoryWrapper: 'flex gap-2 items-center gap-[10px] rounded border border-[#CEDCEC] px-3 py-1.5 w-full',
  categoryImg: 'h-[30px]',
  categoryTitle: 'font-interMedium text-[16px] text-[#101828]',
  changeButton: 'text-[#0A52A1] text-[14px] cursor-pointer',
  categoryModalOverlay: 'flex justify-center items-center fixed top-0 bottom-0 left-0 right-0 bg-[#10182880] z-20',
  photoContainer: 'flex flex-wrap',
  photosWrapper: 'flex flex-row-reverse h-[160px] gap-[12px] overflow-x-auto justify-start',
  photoList: 'flex gap-[12px]',
  photo: 'min-h-[143px] min-w-[187px] max-w-[187px] max-h-[143px] rounded-lg object-cover',
  emptyComponent: 'bg-[#cfc] grid place-items-center bg-[#f4f4f4]',
  imageDeleteIcon: 'cursor-pointer drop-shadow-sm bg-color-white absolute right-[6px] top-[6px] z-10 rounded-full h-[20px] w-[20px] grid place-items-center',
  textarea: 'w-[100%] p-[10px] font-interMedium text-[16px] border border-[#CCDCE8] rounded-lg',
  audioPlayerWrapper: 'flex items-center gap-2',
  audioInput: 'cursor-pointer flex items-center gap-3 bg-[#585D69] w-fit px-4 py-2 rounded',
};
